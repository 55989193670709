// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-es-js": () => import("./../../../src/pages/about.es.js" /* webpackChunkName: "component---src-pages-about-es-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-endorsements-es-js": () => import("./../../../src/pages/endorsements.es.js" /* webpackChunkName: "component---src-pages-endorsements-es-js" */),
  "component---src-pages-endorsements-js": () => import("./../../../src/pages/endorsements.js" /* webpackChunkName: "component---src-pages-endorsements-js" */),
  "component---src-pages-greennewdeal-js": () => import("./../../../src/pages/greennewdeal.js" /* webpackChunkName: "component---src-pages-greennewdeal-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-issues-es-js": () => import("./../../../src/pages/issues.es.js" /* webpackChunkName: "component---src-pages-issues-es-js" */),
  "component---src-pages-issues-js": () => import("./../../../src/pages/issues.js" /* webpackChunkName: "component---src-pages-issues-js" */),
  "component---src-pages-public-safety-js": () => import("./../../../src/pages/public-safety.js" /* webpackChunkName: "component---src-pages-public-safety-js" */),
  "component---src-pages-vote-js": () => import("./../../../src/pages/vote.js" /* webpackChunkName: "component---src-pages-vote-js" */)
}

